@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/**************************[ BASE ]**************************/
body {
	font-family: 'Source Sans Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:16px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Source Sans Pro', sans-serif;
}
h3 {
	font-size:16px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Source Sans Pro', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Source Sans Pro', sans-serif;
}
h6 {
	font-size:24px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Source Sans Pro', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Source Sans Pro', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}
i.fa {
	font-style: normal;
}
/**************************[ HEADER ]**************************/
header#header {
	position: fixed;
	width: 100%;
	z-index: 9998;
}
.xm-grid-header > .container {
	width: 98%;
	max-width: none;
}
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Source Sans Pro', sans-serif;
}
.header .dropdown, .header-top {
	line-height: 3em;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#000;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.desktop-header-container .nav.container, .desktop-header-container .nav.container ul#nav {
	max-width: none;
	width: 100%;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
	display: inline-block;
}
.userName {
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    top: 6px;
    position: relative;
}
.userSwitch {
	margin-left: 5px;
    display: inline-block;
}
.userSwitch a {
	content: "\f0ec";
	text-indent: -9999px;
    display: inline-block;
    color: transparent;
}
.userSwitch a::after {
	content: "\f0ec";
    float: right;
	text-indent: 0;
	font-family: fontAwesome;
}
/* Logo */
.desktop-logo-container {
	position: relative;
    top: 16px;
}
.logo img {
	max-width: 100%;
}
.top-header-links-column {
	max-width: 320px;
}
/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Source Sans Pro', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}
.module-search.flyout .form-search .input-text {
	height: 48px !important;
	line-height: 48px !important;
	color: transparent !important;
}
.module-search.flyout .form-search .input-text:focus, .module-search.flyout .form-search .input-text:hover {
	color: #000 !important;
	background-color: white !important;
	font-weight: bold;
}
.module-search.flyout .form-search .input-text:focus + button.button.button-search, .module-search.flyout .form-search .input-text:hover + button.button.button-search {
	z-index: 1;
	pointer-events: none;
}
.module-search.flyout .form-search .input-text:focus + button.button.button-search .fa, .module-search.flyout .form-search .input-text:hover + button.button.button-search .fa {
	color: #000 !important;
}
.module-search .form-search .button-search {
	line-height: 3em;
	height: 3em;
}
.module-search .form-search .button-search .fa {
	color: #fff !important;
}

.language-column {
	min-width: 60px;
}
.language-column .dropdown-toggle > div {
	line-height: 48px;
}
/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.header span {
	color: #000;
}
.header span.cart-total {
	color: #fff;
}
.header span.fa, .header i.fa {
	color: #fff;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value, .dropdown-toggle * {
	color: #fff;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 0;
	left: 0;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.desktop-nav-column {
	display: block;
    clear: left;
    width: calc(100vw - 2%);
    position: relative;
    left: -21.66%;
}
.desktop-nav-column ul.nav-regular {
	display: flex;
    justify-content: center;
}
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #fff;
	font-size: 16px;
	font-family: 'Source Sans Pro', sans-serif;
	text-transform: none;
}
.links > li > a:hover {
	background-color: #000;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #fff;
}
#nav .nav-panel--dropdown a {
	font-size: 14px;
	font-weight: bold;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#root-wrapper #header a:hover span.fa:before {
	color: #fff;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#nav .nav-item.nav-item--home {
	display: none;
}
.top-header-links-column #mini-cart .empty, .top-header-links-column #mini-cart .caret {
	display: none;
}
.top-header-links-column #mini-cart .dropdown-menu span {
	color: #000;
}
.top-header-links-column #mini-cart .dropdown-menu button span {
	color: #fff;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #000 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #777 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}
.userSwitch a, .userSwitch a:hover {
	color: #fff;
}
.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}
@media only screen and (min-width: 1280px) {
	.main-container {
		padding-top: 110px;
	}
}
@media only screen and (min-width: 960px) and (max-width: 1280px) {
	.main-container {
		padding-top: 180px;
	}
}
@media only screen and (min-width: 960px) {
	a.logo {
		margin-top: 7px;
	}
}
@media only screen and (max-width: 959px) {
	.header .dropdown, .header-top {
		line-height: 36px;
	}
	.main-container {
		padding-top: 45px;
	}
	.nav-cart, .nav-search {
        display: inline-block;
        vertical-align: top;
    }
	.nav-cart {
        height: 36px;
    }
    .nav-cart .fa-shopping-cart {
        /*font-size: 1.5em !important;*/
        font-style: normal;
    }
    .nav-cart .hide-below-960 {
        position: relative;
        display: block !important;
    }
    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }
    .nav-cart .product-details a {
        color: #888;
    }
    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }
    .nav-cart .empty, .nav-cart .caret {
        display: none;
    }
	.nav-search .form-search .button .fa, .nav-cart .fa {
		line-height: 36px;
    }
    .nav-search .input-text {
        display: none;
    }
	.nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
		margin: 0;
    }
    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }
    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }
    .nav-search.fly-out .form-search .button {
        color: #aaa;
        top: 10px;
        right: 25px;
        position: absolute;
        margin: 0;
        padding: 0;
        font-size: inherit;
    }
    .nav-search.fly-out .form-search .button .fa {
        margin: 0 !important;
		color: #000;
    }
    .nav-search.fly-out .form-search .button-search {
        right: 65px;
    }
    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }
    .nav-search.fly-out .form-search .button-close {
        display: block;
    }
}
/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}

.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #000;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}

.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #777 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #43266d;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #43266d;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
.grid12-0.no-margin .grid12-1.no-margin, .grid12-2.no-margin, .grid12-3.no-margin, .grid12-4.no-margin, .grid12-5.no-margin, .grid12-6.no-margin, .grid12-7.no-margin, .grid12-8.no-margin, .grid12-9.no-margin, .grid12-10.no-margin, .grid12-11.no-margin, .grid12-12.no-margin, .grid-col2-main.no-margin, .grid-col2-sidebar.no-margin, .grid-full.no-margin, .no-margin .grid12-0, .no-margin .grid12-1, .no-margin .grid12-2, .no-margin .grid12-3, .no-margin .grid12-4, .no-margin .grid12-5, .no-margin .grid12-6, .no-margin .grid12-7, .no-margin .grid12-8, .no-margin .grid12-9, .no-margin .grid12-10, .no-margin .grid12-11, .no-margin .grid12-12, .no-margin .grid-col2-main, .no-margin .grid-col2-sidebar, .no-margin .grid-full {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.grid12-1.no-margin, .no-margin .grid12-1 {
	width: 8.33%
}
.grid12-2.no-margin, .no-margin .grid12-2 {
	width: 16.66%
}
.grid12-3.no-margin, .no-margin .grid12-3 {
	width: 25%
}
.grid12-4.no-margin, .no-margin .grid12-4 {
	width: 33.33%
}
.grid12-5.no-margin, .no-margin .grid12-5 {
	width: 41.67%
}
.grid12-6.no-margin, .no-margin .grid12-6 {
	width: 50%
}
.grid12-7.no-margin, .no-margin .grid12-7 {
	width: 58.33%
}
.grid12-8.no-margin, .no-margin .grid12-8 {
	width: 66.67%
}
.grid12-9.no-margin, .no-margin .grid12-9 {
	width: 75%
}
.grid12-10.no-margin, .no-margin .grid12-10 {
	width: 83.33%
}
.grid12-11.no-margin, .no-margin .grid12-11 {
	width: 91.67%
}
.grid12-12.no-margin, .grid-col2-main.no-margin, .grid-col2-sidebar.no-margin, .grid-full.no-margin, .no-margin .grid12-12, .no-margin .grid-col2-main, .no-margin .grid-col2-sidebar, .no-margin .grid-full {
	width: 100%
}
@media only screen and (min-width: 960px) {
	.show-below-960 {
		display: none !important;
	}
	button.button span span {
		padding: 0 24px;
	}
}
@media only screen and (max-width: 959px) {
	.hide-below-960 {
		display: none !important;
	}
	button.button span span {
		padding: 0 10px;
	}
}
/**************************[ Footer ]**************************/
#footer {
	background-color: #efefee;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer strong {
	font-weight: 600;
}
.footer-inquiry span {
	line-height: 62px;
	display: block;
	float: left;
	padding: 0 20px;
}
.footer-inquiry span:first-of-type {
	border-right: 1px solid #000;
	padding-left: 0;
}
.footer-icons i {
	font-size: 32px;
	margin-left: 60px;
}
.footer-links-column {
	margin-top: 70px;
}
.footer h6.block-title.heading {
	letter-spacing: normal;
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 0;
}
.footer ul.bullet li {
	padding: 0;
	background: none;
}
.footer ul.bullet li a {
	color: #696969;
	border-bottom: 1px dotted #999;
	font-size: 13px;
}
.footer ul.bullet li a:hover {
	border-bottom: 1px dotted #000;
}
/************************** MICS **************************/


