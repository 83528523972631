/* Category */



.page-type-category .qty-wrapper {
font-size:1em;
margin:5px 0 10px;
}

.products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:65px;
}

.page-type-category .products-line .qty-wrapper {
float:right;
margin-top:-33px;
margin-right:29px;
}

.page-type-category .category-title {
    /*margin-bottom: 1%;*/
}

.page-type-category .std dl,
.page-type-category .std p,
.page-type-category .std address, .std blockquote {
    margin: 0;
}

.page-type-category .horizontal-section .page-content
{
    margin: 0px;
    padding: 0px;
}

.category-products-grid.hover-effect .item:hover {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1%;
    padding-right: 1%;
    box-shadow: none;
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .product-search-container .col-main.grid12-9.in-col2.has-sidebar {
        width: 98%;
    }
}
